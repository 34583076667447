<template>
  <div class="split-view">
    <div class="split-view__column--content">
      <div class="split-view__slot">
        <slot />
      </div>
    </div>
    <div class="split-view__column">
      <ImageWindmill />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import ImageWindmill from '@/imports/ui/components/ImageWindmill.vue'
</script>

<style lang="scss" scoped>
  $module: 'split-view';

  .#{$module} {
    display: flex;
    height: 100vh;
    width: 100vw;

    &__column {
      height: 100%;
      width: 50%;
    }

    &__column--content {
      @extend .#{$module}__column;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__slot {
      width: 400px;
      max-width: calc(100% - 32px);
    }
  }
</style>
