import { IsNumber, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { BASIC_PCF_CALCULATIONS_CONTROLLER } from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

export const CREATE_BASIC_PCF_CACHE_SUBPATH = 'cache'
export const CREATE_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${CREATE_BASIC_PCF_CACHE_SUBPATH}`

export class CreateBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsNumber()
  year!: number
}

export type CreateBasicPCFCacheRes = {
  cacheId: string // uuid
}
