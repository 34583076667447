<template>
  <div id="app">
    <RouteLoader v-if="isLoading"></RouteLoader>

    <IfFeatureDisabled flagName="rollout-mobile-version">
      <ResponsiveWarning></ResponsiveWarning>
    </IfFeatureDisabled>

    <div :class="{ 'responsive-warning-active': !mobileStateEnabled }">
      <component
        :is="layout"
        :showBetaHeader="showBetaHeader"
      >
        <router-view></router-view>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, inject, ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useRoute } from 'vue-router'

  import featureFlagService from '@lib/services/featureFlagService'

  import IfFeatureDisabled from '@components/IfFeatureDisabled.vue'
  import ResponsiveWarning from '@components/ResponsiveWarning.vue'
  import RouteLoader from '@components/RouteLoader.vue'

  import { INTERCOM_KEY } from '@lib/plugins/VueIntercom'

  import { useAppStore } from '@/client/store/app/app.pinia'
  import { useOrganizationStore } from '@/client/store/organization.pinia'
  import { useCorporateFootprintsStore } from '@/client/store/corporateFootprints/corporateFootprints.pinia'
  import { useUserStore } from '@/client/store/user.pinia'

  import type { Organization } from '@/imports/@types/Organization'

  const mobileStateEnabled = ref(false)

  const appStore = useAppStore()
  const organizationStore = useOrganizationStore()
  const userStore = useUserStore()
  const corporateFootprintsStore = useCorporateFootprintsStore()

  const intercom = inject(INTERCOM_KEY)

  const { loaders } = storeToRefs(appStore)
  const isLoading = computed(() => loaders.value.route)

  const route = useRoute()

  const layout = computed(() => {
    // fix issue that initial this.$route is undefined when starting app
    // causing it to show default-layout briefly
    if (!route.name) return

    if (!route.meta.layout) return 'default-layout'

    return `${route.meta.layout}-layout`
  })

  const showBetaHeader = computed(() => {
    return !!route.meta?.showBetaHeader
  })

  const updateMobileState = () => {
    mobileStateEnabled.value = featureFlagService.isEnabled('rollout-mobile-version')
  }

  const listenForLogin = () => {
    organizationStore.$onAction(({ name, after }) => {
      if (name === 'setActiveOrganizationById') {
        after(() => {
          // there's a reasoneable chance Intercom is not mounted yet
          if (intercom) {
            intercom.boot(
              userStore.user,
              organizationStore.activeOrganization as Organization,
              organizationStore.organizations.map(org => org.name).join(', '),
              userStore.intercomHash,
            )
          }

          corporateFootprintsStore.getYearlyCompletion()
        })
      }
    })

    userStore.$onAction(({ name, after }) => {
      if (name === 'setUser') {
        after(() => {
          appStore.fetchCountriesList()
        })
      }
    })
  }

  const listenForLogout = () => {
    userStore.$onAction(({ name, after }) => {
      if (name === 'clearSession') {
        after(() => {
          if (intercom) intercom.shutdown()
        })
      }
    })
  }

  listenForLogin()
  listenForLogout()
  updateMobileState()

  featureFlagService.subscribeToContext(updateMobileState)
</script>

<style lang="scss">
  @import '@/client/style/index.scss';

  .responsive-warning-active {
    @media (max-width: 1023px) {
      display: none;
    }

    @media print {
      display: block;
    }
  }
</style>
