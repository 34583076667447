import { IsString } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

// Single product page
// [GET] /products/:productId
export const PRODUCT_INFO_SUBPATH = ':productId/detail'
export const PRODUCT_INFO_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_INFO_SUBPATH}`

export class ProductInfoDTOReq extends ValidateDTO {
  @IsString()
  productId!: string

  startMonth!: string

  endMonth!: string
}

export type ProductInfoDTORes = {
  name: string
  category: string
  UOM: string
  hasDataForDateRange: boolean
}
