<template>
  <div>
    <div
      ref="pageContent"
      class="page-content"
      data-scroll="page-content"
    >
      <AqLoader v-if="!$slots.default" />
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import AqLoader from '@reinga/AqLoader/AqLoader.vue'
</script>

<style lang="scss" scoped>
  .page-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
