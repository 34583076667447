<template>
  <component
    :is="element"
    :class="[
      'aq-label',
      {
        'aq-label--small': isSmall,
        'aq-label--large-margin': largeMargin,
      },
    ]"
    :style="{ 'font-weight': fontWeight }"
  >
    <slot></slot>
    <span
      v-if="isOptional"
      class="aq-label__optional"
      >Optional</span
    >
  </component>
</template>

<script lang="ts" setup>
  type Props = {
    element?: string
    isSmall?: boolean
    largeMargin?: boolean
    fontWeight?: string
    isOptional?: boolean
  }

  withDefaults(defineProps<Props>(), {
    element: 'span',
    fontWeight: '500',
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-label';

  .#{$module} {
    color: $text-primary;
    display: block;
    line-height: math.div(20, $font-size-6-unitless);
    margin-bottom: $grid-size-margin;
    font-size: $font-size-6;
    font-weight: 500;
    padding-top: $grid-size-padding * 2;

    &--small {
      font-size: $font-size-6;
    }

    &--large-margin {
      margin-bottom: $grid-size-margin * 1.25;
    }

    &__optional {
      color: $text-secondary;
      font-size: $font-size-6;
      margin-left: $grid-size-margin;
      font-weight: 400;
    }
  }
</style>
