<template>
  <svg
    class="grid-icon"
    :width="width"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 2.625C0.25 1.60938 1.07031 0.75 2.125 0.75H5.875C6.89062 0.75 7.75 1.60938 7.75 2.625V6.375C7.75 7.42969 6.89062 8.25 5.875 8.25H2.125C1.07031 8.25 0.25 7.42969 0.25 6.375V2.625ZM1.5 2.625V6.375C1.5 6.72656 1.77344 7 2.125 7H5.875C6.1875 7 6.5 6.72656 6.5 6.375V2.625C6.5 2.3125 6.1875 2 5.875 2H2.125C1.77344 2 1.5 2.3125 1.5 2.625ZM0.25 12.625C0.25 11.6094 1.07031 10.75 2.125 10.75H5.875C6.89062 10.75 7.75 11.6094 7.75 12.625V16.375C7.75 17.4297 6.89062 18.25 5.875 18.25H2.125C1.07031 18.25 0.25 17.4297 0.25 16.375V12.625ZM1.5 12.625V16.375C1.5 16.7266 1.77344 17 2.125 17H5.875C6.1875 17 6.5 16.7266 6.5 16.375V12.625C6.5 12.3125 6.1875 12 5.875 12H2.125C1.77344 12 1.5 12.3125 1.5 12.625ZM15.875 0.75C16.8906 0.75 17.75 1.60938 17.75 2.625V6.375C17.75 7.42969 16.8906 8.25 15.875 8.25H12.125C11.0703 8.25 10.25 7.42969 10.25 6.375V2.625C10.25 1.60938 11.0703 0.75 12.125 0.75H15.875ZM15.875 2H12.125C11.7734 2 11.5 2.3125 11.5 2.625V6.375C11.5 6.72656 11.7734 7 12.125 7H15.875C16.1875 7 16.5 6.72656 16.5 6.375V2.625C16.5 2.3125 16.1875 2 15.875 2ZM10.25 12.625C10.25 11.6094 11.0703 10.75 12.125 10.75H15.875C16.8906 10.75 17.75 11.6094 17.75 12.625V16.375C17.75 17.4297 16.8906 18.25 15.875 18.25H12.125C11.0703 18.25 10.25 17.4297 10.25 16.375V12.625ZM11.5 12.625V16.375C11.5 16.7266 11.7734 17 12.125 17H15.875C16.1875 17 16.5 16.7266 16.5 16.375V12.625C16.5 12.3125 16.1875 12 15.875 12H12.125C11.7734 12 11.5 12.3125 11.5 12.625Z"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'grid-icon';

  .#{$module} {
    display: block;
    fill: $kelp-superlight;

    path {
      stroke-width: 0.3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
