import { IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { ValidateDTO } from '..'

import {
  BASIC_PCF_CALCULATIONS_CONTROLLER,
  FacilitiesForms,
} from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

export const UPDATE_BASIC_PCF_CACHE_SUBPATH = 'cache/:cacheId'
export const UPDATE_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${UPDATE_BASIC_PCF_CACHE_SUBPATH}`

export class UpdateBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  cacheId!: string

  @ValidateNested()
  @Type(() => FacilitiesForms)
  facilitiesForms!: FacilitiesForms
}

export type UpdateBasicPCFCacheRes = {
  success: true
}
