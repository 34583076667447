// Get static dashboard URI

import { IsEnum, IsOptional } from 'class-validator'

import { ValidateDTO } from '..'

import { ANALYTICS_CONTROLLER, type AnalyticsIframeFormatting, METABASE_EMBED_TYPE } from './controller.common-vars'

export const ANALYTICS_STATIC_SUBPATH = 'embed/:embedId'
export const ANALYTICS_STATIC_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_STATIC_SUBPATH}`

export class AnalyticsStaticDashboardDTOReq extends ValidateDTO {
  embedId!: number

  @IsOptional()
  @IsEnum(METABASE_EMBED_TYPE)
  embedType: METABASE_EMBED_TYPE = METABASE_EMBED_TYPE.DASHBOARD

  formatting?: AnalyticsIframeFormatting

  useInternalMetabase?: boolean
}

export type AnalyticsStaticDashboardDTORes = {
  uri: string
}
