<template>
  <p :class="['paragraph', { 'paragraph--no-margin': noMargin }]"><slot></slot></p>
</template>

<script lang="ts" setup>
  type Props = {
    noMargin?: boolean
  }

  defineProps<Props>()
</script>

<style lang="scss" scoped>
  $module: 'paragraph';

  .#{$module} {
    margin-bottom: $grid-size-margin * 2;

    &--no-margin {
      margin-bottom: 0;
    }
  }
</style>
