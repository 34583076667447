<template>
  <slot></slot>
</template>

<script>
  export default {
    name: 'PdfLayout',
  }
</script>

<style lang="scss">
  // This class gets added during Cypress execution
  html.pdf-report-cypress {
    height: auto !important;

    body {
      height: auto !important;
    }

    .customer-report {
      transform: scale(1) !important;
    }
  }
</style>
