<template>
  <svg
    :width="width"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 9.375C1.25 10.2038 1.57924 10.9987 2.16529 11.5847C2.75134 12.1708 3.5462 12.5 4.375 12.5C5.2038 12.5 5.99866 12.1708 6.58471 11.5847C7.17076 10.9987 7.5 10.2038 7.5 9.375C7.5 8.5462 7.17076 7.75134 6.58471 7.16529C5.99866 6.57924 5.2038 6.25 4.375 6.25C3.5462 6.25 2.75134 6.57924 2.16529 7.16529C1.57924 7.75134 1.25 8.5462 1.25 9.375V9.375Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 5C12.5 5.8288 12.8292 6.62366 13.4153 7.20971C14.0013 7.79576 14.7962 8.125 15.625 8.125C16.4538 8.125 17.2487 7.79576 17.8347 7.20971C18.4208 6.62366 18.75 5.8288 18.75 5C18.75 4.1712 18.4208 3.37634 17.8347 2.79029C17.2487 2.20424 16.4538 1.875 15.625 1.875C14.7962 1.875 14.0013 2.20424 13.4153 2.79029C12.8292 3.37634 12.5 4.1712 12.5 5Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 15C12.5 15.8288 12.8292 16.6237 13.4153 17.2097C14.0013 17.7958 14.7962 18.125 15.625 18.125C16.4538 18.125 17.2487 17.7958 17.8347 17.2097C18.4208 16.6237 18.75 15.8288 18.75 15C18.75 14.1712 18.4208 13.3763 17.8347 12.7903C17.2487 12.2042 16.4538 11.875 15.625 11.875C14.7962 11.875 14.0013 12.2042 13.4153 12.7903C12.8292 13.3763 12.5 14.1712 12.5 15Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.28857 8.2425L12.7119 6.13333"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.1709 10.7733L12.8292 13.6025"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;

    path {
      stroke: $grey-2;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
