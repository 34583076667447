import { BASIC_FACILITY_DATA_STATUS } from '../../../@enums/basic-facilities.enums'

import { BASIC_FACILITIES_CONTROLLER } from './controller.common-vars'

export const BASIC_FACILITY_STATUS_SUBPATH = 'statuses/overall'
export const BASIC_FACILITY_STATUS_URI = `${BASIC_FACILITIES_CONTROLLER}/${BASIC_FACILITY_STATUS_SUBPATH}`

export type BasicFacilityStatusesResponse = {
  statuses: BASIC_FACILITY_DATA_STATUS[]
}
