<template>
  <component
    :is="element"
    :type="!elementOverride ? type : ''"
    :disabled="disabled || loading"
    :class="[
      'aq-btn',
      variant,
      { 'icon-only': iconOnly },
      size,
      { expanded, disabled, loading, rounded, active: isActive },
    ]"
    :target="targetAttribute"
    :href="href && !to ? href : undefined"
    :to="to"
  >
    <Icon
      v-if="variant === 'overflow'"
      icon="ellipsis-v"
      pack="far"
    />
    <template v-if="variant !== 'overflow'">
      <Icon
        v-if="iconLeft && !loading"
        :pack="iconLeftPack"
        :icon="iconLeft"
      />
      <Spinner
        v-if="loading"
        class="aq-btn__loading"
        :size="size"
        :color="spinnerColor"
      />
      <div
        v-if="label || $slots.default"
        class="aq-btn__txt"
        data-ci="aq-btn-txt"
        :class="{ 'ml-1': iconLeft, 'mr-1': iconRight, invisible: loading }"
      >
        <span v-if="label && !$slots.default">{{ label }}</span>
        <slot />
      </div>
      <div
        v-if="addSpaceBtwnTextAndRightIcon"
        style="flex-grow: 1"
      ></div>
      <Icon
        v-if="iconRight && !loading"
        :pack="iconRightPack"
        :icon="iconRight"
      />
    </template>
  </component>
</template>

<script lang="ts">
  import type { PropType } from 'vue'
  import { defineComponent } from 'vue'

  import Icon from '@components/Icon.vue'
  import Spinner from '@reinga/Spinner/Spinner.vue'

  export default defineComponent({
    name: 'AqButton',

    components: {
      Icon,
      Spinner,
    },

    props: {
      type: {
        type: String as PropType<'button' | 'submit' | 'reset'>,
        default: 'button',
      },

      loading: {
        type: Boolean,
        default: false,
      },

      disabled: {
        type: Boolean,
      },

      label: {
        type: String,
        default: '',
      },

      href: {
        type: String,
        default: '',
      },

      to: {
        type: [String, Object],
        default: '',
      },

      variant: {
        type: String,
        default: '',
      },

      expanded: {
        type: Boolean,
        default: false,
      },

      size: {
        type: String,
        default: 'medium',
      },

      iconLeft: {
        type: String,
        default: '',
      },

      iconLeftPack: {
        type: String,
        default: 'fal',
      },

      iconRight: {
        type: String,
        default: '',
      },

      iconRightPack: {
        type: String,
        default: 'fal',
      },

      rounded: {
        type: Boolean,
      },

      /**
       * Used when the button is a toggle for
       * a particular state and our variants have
       * a certain style associated with it
       */
      isActive: {
        type: Boolean,
      },

      /**
       * Useful when you need something that looks like
       * a button but isn't a semantically a button. Common
       * use case will be in a situation where we're trying to
       * pass content into a slot that output within a generic
       * button.
       */
      elementOverride: {
        type: String,
        default: '',
      },

      openInNewTab: {
        type: Boolean,
      },

      iconOnly: {
        type: Boolean,
        default: false,
      },

      addSpaceBtwnTextAndRightIcon: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      element() {
        const { href, to, elementOverride } = this

        if (elementOverride) return elementOverride

        if (!href && !to) return 'button'

        if (href.includes('http')) return 'a'

        return 'router-link'
      },

      spinnerColor(): string {
        return ['primary', 'danger', 'blue'].includes(this.variant) ? 'grey-8' : 'kelp'
      },

      // Open external links in a new window
      targetAttribute(): string {
        return this.href?.includes('http') || this.openInNewTab ? '_blank' : ''
      },
    },
  })
</script>

<style lang="scss">
  .aq-btn {
    $border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $grey-8;
    position: relative;

    &:hover {
      color: $kelp;
    }

    &__txt {
      display: flex;
      align-items: center;
    }

    &.loading {
      align-items: center;
      min-height: 24px;
      min-width: 50px;

      &.small {
        min-height: 20px;
        min-width: 50px;
      }

      &.large {
        min-height: 24px;
        min-width: 50px;
      }

      .btn__txt {
        opacity: 0;
      }
    }

    &__loading {
      position: absolute;
      left: 50%;
      transform: translateX(-4px);
    }

    // states
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    // variants
    &.primary {
      background: $kelp;
      color: white;

      &.disabled {
        color: $grey-3;
        background-color: $grey-5;
      }

      &:focus {
        outline-color: $kelp;
      }

      &:hover {
        background: $kelp-dark;
        color: white !important;
      }

      &:active {
        background-color: $active-link-bg;
        color: white !important;
      }
    }

    &.secondary {
      border: 1px solid $grey-4;
      color: $grey-2;

      &.disabled {
        color: $grey-3;
        background-color: $grey-6;
        border: 0;
      }

      &:focus {
        border: solid $kelp 1px;
        outline: solid $kelp 1px;
      }

      &:hover {
        color: $kelp;
        background-color: $green-light;
      }

      &:active {
        background-color: $green-medium;
      }
    }

    &.transparent {
      color: $grey-2;
      border: 1px solid $grey-4;
      background: transparent;
    }

    &.rounded {
      border-radius: 9999px;
      padding: $grid-size-margin;
      min-height: $grid-size-margin * 4;
      min-width: $grid-size-margin * 4;
    }

    &.tertiary {
      color: $grey-2;

      &.disabled {
        color: $grey-3;
      }

      &:hover {
        color: $kelp;
        background-color: $green-light;
      }

      &:focus {
        outline-color: $kelp;
      }

      &:active {
        background-color: $green-medium;
      }
    }

    &.ghost {
      color: #666;
      border: none;
      background: none;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
      }

      &.disabled {
        color: $grey-3;
      }
    }

    &.danger {
      background: $red-dark;
      color: white;

      &:focus {
        outline-color: #bf0000;
      }

      &:hover {
        background: #b20000;
      }

      &:active {
        background-color: #990000;
      }

      &.disabled {
        color: $grey-3;
        background-color: $grey-6;
      }
    }

    &.danger-icon {
      background-color: $grey-8;
      color: $grey-1;

      &:hover {
        background: $red-light;
        color: $red-dark;
      }

      &:active {
        color: $red-dark;
        outline-color: $red-dark;
        background: #fde5e5;
      }

      &:focus {
        outline-color: #bf0000;
      }

      &.disabled {
        color: $grey-3;
      }
    }

    &.danger-secondary {
      color: $red-dark;
      border: 1px solid $grey-4;

      &:focus {
        outline-color: #bf0000;
        background: $red-light;
      }

      &:hover {
        background: #bf0000;
        color: $grey-8;
      }
    }

    &.blue {
      background: #007dc1;
      color: $grey-8;

      &:hover {
        background: #006399;
      }

      &:active {
        background: #fde5e5;
      }

      &.disabled {
        color: $grey-3;
        background-color: $grey-6;
      }
    }

    &.is-success {
      color: $grey-8;
      background-color: $green-dark;
    }

    &.overflow {
      width: 40px;
      background-color: $grey-8;
      color: $grey-2;
      border: 1px solid $grey-8;

      &.disabled {
        color: $grey-3;
        background-color: $grey-6;
      }

      &:hover {
        color: $kelp;
      }

      &:active {
        color: $kelp;
        background-color: $grey-8;
        border: 1px solid $kelp;
      }
    }

    &.icon-only {
      $default-btn-size: 44px;
      $small-btn-size: 36px;
      $large-btn-size: 52px;

      width: $default-btn-size;
      height: $default-btn-size;
      color: $grey-1;
      font-size: 18px;

      &.small {
        width: $small-btn-size;
        height: $small-btn-size;
      }

      &.large {
        width: $large-btn-size;
        height: $large-btn-size;
      }
    }

    &.is-grouped {
      border: 1px solid $grey-7;
      border-radius: 0;

      &:before {
        border-top: 1px solid $grey-7;
        width: 1px;
        height: 100%;
      }

      &.active {
        border-width: 1px;
        border-radius: $border-radius;
        & + .aq-btn {
          border-left-width: 0 !important;
        }
      }

      &:first-of-type {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-of-type {
        border-right-width: 1px !important;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    &.filter {
      background-color: $grey-8;
      color: $grey-2;
      border: 1px solid $grey-4;
      height: 44px;

      :deep(.icon) {
        color: $grey-2;
      }

      &.disabled {
        color: $grey-3;
        background-color: $grey-6;

        :deep(.icon) {
          color: $grey-3;
        }
      }

      &.active {
        color: $kelp;
        background-color: $green-light;
        border: 1px solid $kelp;

        :deep(.icon) {
          color: $kelp;
        }

        &.disabled {
          color: $grey-3;
          background-color: $grey-6;
          border: 1px solid $grey-3;

          :deep(.icon) {
            color: $grey-3;
          }
        }
      }

      &:hover {
        :deep(.icon) {
          color: $kelp;
        }
      }
    }

    // size
    &.expanded {
      width: 100%;
    }

    &.small {
      padding: 8px 12px;
      font-size: 16px;
    }

    &.large {
      padding: 14px 16px;
      font-size: 18px;
    }

    &.reset {
      font-size: inherit;
      font-weight: inherit;
      padding: 0;
      background-image: none;
      margin: 0;
      border: 0;
      outline: 0;
      display: inline-block;
      background-color: transparent;
      line-height: inherit;
      border-radius: 0;
      text-align: left;
    }

    .cube1,
    .cube2 {
      background: $grey-8 !important;
    }

    &__txt {
      &.invisible {
        opacity: 0;
      }

      .fal {
        padding-right: 8px;
      }
    }

    &.active {
      background: $green-medium !important;
      color: $kelp !important;
      border-color: $green-medium;
    }
  }
</style>
