import type { AxiosInstance } from 'axios'

import { DATACONTRACT_DELETE_URI, type DeleteDataContractDTOReq } from '@lib/DTO/dataSource/delete-datacontract.dto'
import { replaceUriParams } from '@lib/DTO'
import {
  SELF_SERVE_UPLOAD_DELETABLE_URI,
  type DeletionRules,
  type MaintenanceErrors,
} from '@lib/DTO/self-serve/deletable-self-serve.dto'
import {
  SELF_SERVE_UPLOAD_REQUEST_DELETE_URI,
  type SelfServeRequestDeleteDTORes,
} from '@lib/DTO/self-serve/request-delete-self-serve.dto'
import { SELF_SERVE_UPLOAD_DELETE_URI } from '@lib/DTO/self-serve/delete-self-serve.dto'
import { GET_CUSTOM_CALCULATION_TEMPLATE_URI } from '@lib/DTO/dataSource/get-custom-calculation-template.dto'
import { type GetDataContractDTOReq, DATACONTRACT_GET_URI } from '@lib/DTO/dataSource/get-datacontract.dto'
import { GET_DATA_SOURCE_ACTIVITIES_URI } from '@lib/DTO/config/get-data-source-activities.dto'

import { PRODUCT_DATA_SOURCE_GET_URI } from '../../DTO/dataSource/get-product-data-source.dto'

import { DATA_SOURCE_TARGET_MODULE } from '@/imports/@enums/data-source.enums'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { Paginated } from '@/imports/@types/Paginated'
import type {
  DataSourceActivityUpdateFields,
  DataSourceActivity,
} from '@/imports/@types/datasources/DataSourceActivity'
import type { OrganizationUser } from '@/imports/@types/User'
import type {
  SelfServeGetTemplateFileResponse,
  SelfServeUploadFieldMapping,
  SelfServeUploadGetAllResponse,
  SelfServeUploadGetResponse,
  SelfServeUploadTemplate,
  SelfServeStatusUpdatePayload,
  SelfServeUploadDeletePayload,
} from '@/imports/@types/datasources/SelfServeUploads'
import type {
  DataSource,
  DataSourceFacilityListItem,
  DataSourceBusinessUnitListItem,
  DataSourceShort,
  DataSourcesStatusCounts,
  DataSourceTypeV2Id,
  ProductDataSource,
} from '@/imports/@types/DataSource'
import type {
  GetCustomCalculationTemplatePayload,
  GetCustomCalculationTemplateResponse,
} from '@/imports/@types/datasources/CustomCalculations'
import type { OSTemplateConfig } from '@/imports/@types/dataImporter/OSTemplateConfig'

type UpdateDataSourcePayload = {
  orgId: string
  dataSourceId: string
  fields: {
    activities?: DataSourceActivityUpdateFields[]
    facilities?: { facilityId: string; businessUnitId: string }[] | string[]
    businessUnits?: string[]
    name?: string
    description?: string
    status?: string
    assumptions?: string[]
    osTemplateConfig?: OSTemplateConfig | null
    doesOneSchemaUpload?: boolean
  }
}

export type FetchDataSourcesPayload = {
  orgId: string
  subActivities?: string
  facilities?: string
  pageNo: number
  perPage: number
  searchTerm?: string
  sortField: string
  sortOrder: string
  sort?: null | string
  status: string
  type: DataSourceTypeV2Id
  assignedToMe: boolean
  includeStatusData?: boolean
  targetModule: 'corporate'
}

export type CreateDataSourcePayload = {
  orgId: string
  name: string
  startDate: string
  endDate?: string
  activities: DataSourceActivity[]
  updateFrequency: string
  type: string
  description?: string
  targetModule?: DATA_SOURCE_TARGET_MODULE // API defaults to corporate
}

export type GetFacilityListPayload = {
  orgId: string
  dataSourceId: string
}

export type GetFacilityListPayloadV2 = GetFacilityListPayload & {
  perPage?: number
  pageNo?: number
  query?: string
}

type CreateDataContractPayload = {
  orgId: string
  dataSourceId: string
  dataContractUrl: string
}

type DataSourcesListResponse = {
  count: string
  rows: DataSourceShort[]
  meta: { statusData: DataSourcesStatusCounts }
}

export class DataSourceApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getDataSources(payload: {
    orgId: string | string[]
    page?: number
    limit?: number
    query?: string | null
    targetModule?: DATA_SOURCE_TARGET_MODULE // API defaults to corporate
    includeAssumptions?: boolean
  }) {
    return this.http.get<ApiResponseInner<{ rows: DataSource[]; count: string }>>(
      `datasource/getForOrganization${Array.isArray(payload.orgId) ? 's' : ''}`,
      {
        params: {
          ...(Array.isArray(payload.orgId) ? { orgIds: payload.orgId } : { orgId: payload.orgId }),
          ...(payload.query ? { searchTerm: payload.query } : {}),
          pageNo: payload.page || 1,
          perPage: payload.limit || 20,
          targetModule: payload.targetModule,
          includeAssumptions: payload.includeAssumptions ?? false,
        },
      },
    )
  }

  getDataSource(payload: { dataSourceId: string; orgId: string }) {
    return this.http.get<ApiResponseInner<DataSource>>('datasource/get', {
      params: payload,
    })
  }

  getDataContracts(payload: GetDataContractDTOReq) {
    return this.http.get<ApiResponseInner<DataSource>>(replaceUriParams(DATACONTRACT_GET_URI, payload))
  }

  fetchDataSources(payload: FetchDataSourcesPayload) {
    return this.http.get<ApiResponseInner<DataSourcesListResponse>>('datasource/getfororganization', {
      params: {
        ...payload,
        sort: 'null',
      },
    })
  }

  createDataSource(payload: CreateDataSourcePayload) {
    return this.http.post<ApiResponseInner<DataSource>>('datasource/create', payload)
  }

  updateDataSource(payload: UpdateDataSourcePayload) {
    return this.http.patch<ApiResponseInner<undefined>>('dataSource/update', payload)
  }

  createDataContract(payload: CreateDataContractPayload) {
    return this.http.post<ApiResponseInner<undefined>>('dataSource/dataContract', payload)
  }

  finishBatchUpload(payload: { orgId: string; dataSourceId: string; filesUploaded: number }) {
    return this.http.post<ApiResponseInner<undefined>>('dataSource/finishBatchUpload', payload)
  }

  getSelfServeUpload(payload: { selfServeUploadId: string; dataSourceId: string; orgId: string }) {
    return this.http.get<ApiResponseInner<SelfServeUploadGetResponse>>('selfserveupload/get', {
      params: payload,
    })
  }

  getAllSelfServeUploads(payload: { dataSourceId: string; orgId: string }) {
    return this.http.get<ApiResponseInner<Paginated<SelfServeUploadGetAllResponse>>>(
      'selfserveupload/getallfororganization',
      {
        params: payload,
      },
    )
  }

  getValidationTemplate(payload: { activityId: number; subActivityId: number; calcMethodCode: string; orgId: string }) {
    return this.http.get<ApiResponseInner<SelfServeUploadTemplate>>('selfserveupload/getvalidationtemplate', {
      params: payload,
    })
  }

  createSelfServeUpload(payload: {
    dataSourceId: string
    orgId: string
    data: Array<unknown>
    errorCount: number
    fileName: string
  }) {
    return this.http.post<ApiResponseInner<string>>('selfserveupload/create', payload)
  }

  updateSelfServeUpload(payload: {
    selfServeUploadId: string
    orgId: string
    data: Array<unknown>
    errorCount: number
    fieldMapping: SelfServeUploadFieldMapping
    headerRow: number
    status: string
  }) {
    return this.http.patch<ApiResponseInner<unknown>>('selfserveupload/update', payload)
  }

  updateSelfServeUploadStatus(payload: SelfServeStatusUpdatePayload) {
    return this.http.patch<unknown>('selfserveupload/updateStatus', payload)
  }

  deleteDataContractUrl(payload: DeleteDataContractDTOReq) {
    return this.http.delete<ApiResponseInner<DataSource>>(replaceUriParams(DATACONTRACT_DELETE_URI, payload))
  }

  deleteSelfServeUpload(payload: SelfServeUploadDeletePayload) {
    const requestUrl = replaceUriParams(SELF_SERVE_UPLOAD_DELETE_URI, payload)

    return this.http.delete<ApiResponseInner<unknown>>(requestUrl, {
      params: payload,
    })
  }

  submitSelfServeUpload(payload: { selfServeUploadId: string; orgId: string }) {
    return this.http.post<ApiResponseInner<unknown>>('selfserveupload/submit', payload)
  }

  downloadSelfServeTemplate(payload: {
    dataSourceId: string
    activityId: number
    subActivityId: number
    calcMethodCode: string
    orgId: string
  }) {
    return this.http.get<ApiResponseInner<SelfServeGetTemplateFileResponse>>('selfserveupload/getTemplate', {
      params: payload,
    })
  }

  getDataSourceActivities() {
    return this.http.get<ApiResponseInner<DataSourceActivity[]>>(GET_DATA_SOURCE_ACTIVITIES_URI)
  }

  getAssignmentUsers(orgId: string) {
    return this.http.get<ApiResponseInner<OrganizationUser[]>>('datasource/getassignmentusers', {
      params: {
        orgId,
      },
    })
  }

  getDataSourceFacilitiesList(payload: GetFacilityListPayload) {
    return this.http.get<{ facilities: DataSourceFacilityListItem[] }>('datasource/getFacilitiesList', {
      params: payload,
    })
  }

  getDataSourceFacilitiesListV2(payload: GetFacilityListPayloadV2) {
    return this.http.get<{ facilities: DataSourceFacilityListItem[]; totalCount: number }>(
      'datasource/getFacilitiesList',
      {
        params: payload,
      },
    )
  }

  getDataSourceBusinessUnitsList(payload: GetFacilityListPayload) {
    return this.http.get<{ businessUnits: DataSourceBusinessUnitListItem[] }>('datasource/getBusinessUnitList', {
      params: payload,
    })
  }

  getCustomCalculationTemplate(payload: GetCustomCalculationTemplatePayload) {
    return this.http.get<GetCustomCalculationTemplateResponse>(GET_CUSTOM_CALCULATION_TEMPLATE_URI, {
      params: payload,
    })
  }

  getCanDeleteCheck(selfServeId: string, orgId: string) {
    const requestURI = replaceUriParams(SELF_SERVE_UPLOAD_DELETABLE_URI, { selfServeUploadId: selfServeId })

    return this.http.get<{ maintenanceErrors: MaintenanceErrors | undefined; deletionRules: DeletionRules }>(
      requestURI,
      {
        params: {
          orgId,
        },
      },
    )
  }

  getProductDataSource(orgId: string) {
    return this.http.get<{ dataSource: ProductDataSource }>(PRODUCT_DATA_SOURCE_GET_URI, {
      params: {
        orgId,
      },
    })
  }

  requestDeleteSelfServeUpload(orgId: string, selfServeUploadId: string, isUserPlanningToModify: boolean) {
    const requestURI = replaceUriParams(SELF_SERVE_UPLOAD_REQUEST_DELETE_URI, {
      selfServeUploadId,
      isUserPlanningToModify,
    })

    return this.http.patch<SelfServeRequestDeleteDTORes>(requestURI, {
      selfServeUploadId,
      orgId,
      isUserPlanningToModify,
    })
  }
}
