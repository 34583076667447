// Facility with highest total product emissions
// [GET] /products/:productId/metric-highest-facility?startMonth=01-2022&endMonth=12-2022
import { IsOptional, IsString, IsUUID } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_METRIC_HIGHEST_FACILITY_SUBPATH = ':productId/metric-highest-facility'
export const PRODUCT_METRIC_HIGHEST_FACILITY_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_METRIC_HIGHEST_FACILITY_SUBPATH}`

export class HighestProductFacilityEmissionsDTOReq {
  startMonth!: string

  endMonth!: string

  @IsString()
  productId!: string

  @IsUUID()
  @IsOptional()
  facilityId?: string
}

export type HighestProductFacilityEmissionsDTORes = {
  facilityId?: string
  facilityName?: string
  countryCode?: string
  emissions: number
  emissionUnit: string // changed from emissionsUnit
  isBu: boolean
}
