import { IsEnum, IsOptional, IsString } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

import type { PaginationMeta } from '../../generic/pagination-meta.dto'

import { ValidateDTO } from '../..'
import { SORT_ORDER } from '../../../../@enums/common.enums'

export enum PRODUCT_FACILITY_EMISSIONS_SORT_FIELDS {
  FACILITY_NAME = 'name',
  TOTAL_EMISSIONS = 'emissions',
  TOTAL_QUANTITY = 'totalQuantity',
  ITEMS_PRODUCED = 'itemsProduced',
  EMISSIONS_PER_PRODUCT = 'emissionsPerProduct',
}

// Product emissions per facility
// [GET] /products/:productId/facilities/details?searchTerm=foo&category=bar&perPage=10&pageNo=1&startMonth=01-2022&endMonth=12-2022
export const PRODUCT_FACILITY_DETAILS_SUBPATH = ':productId/facilities/details'
export const PRODUCT_FACILITY_DETAILS_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_FACILITY_DETAILS_SUBPATH}`

export class ProductEmissionsPerFacilityDTOReq extends ValidateDTO {
  searchTerm?: string

  perPage!: number

  pageNo!: number

  startMonth!: string

  endMonth!: string

  @IsString()
  productId!: string

  @IsOptional()
  @IsEnum(PRODUCT_FACILITY_EMISSIONS_SORT_FIELDS)
  sortBy?: PRODUCT_FACILITY_EMISSIONS_SORT_FIELDS

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER
}

export type ProductFacilityEmissions = {
  facilityId: string
  facilityName: string
  countryCode: string
  emissions: number
  emissionUnit: string // changed from emissionsUnit
  itemsProduced: number
  totalQuantity: number // changed
  quantityUnit: string // changed
}

export type ProductEmissionsPerFacilityDTORes = {
  productEmissionsPerFacility: ProductFacilityEmissions[]
  meta: PaginationMeta & {
    sortBy?: PRODUCT_FACILITY_EMISSIONS_SORT_FIELDS
  }
}
