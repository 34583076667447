<template>
  <svg
    :width="width"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.494141 4.21289L3.75977 1.14062C3.86719 1.0332 3.99609 0.96875 4.125 0.96875C4.23242 0.96875 4.36133 1.0332 4.46875 1.11914L7.73438 4.19141C7.94922 4.38477 7.94922 4.70703 7.75586 4.92188C7.5625 5.13672 7.24023 5.13672 7.02539 4.94336L4.10352 2.19336L1.20312 4.94336C0.988281 5.13672 0.666016 5.13672 0.472656 4.92188C0.279297 4.72852 0.279297 4.40625 0.494141 4.21289Z"
      fill="#F8F8F8"
    />
    <path
      d="M7.73438 12.5586L4.46875 15.6309C4.36133 15.7383 4.23242 15.7812 4.125 15.7812C3.99609 15.7812 3.86719 15.7383 3.75977 15.6523L0.494141 12.5586C0.279297 12.3652 0.279297 12.043 0.472656 11.8281C0.666016 11.6133 0.988281 11.6133 1.20312 11.8066L4.125 14.5566L7.02539 11.8066C7.24023 11.6133 7.5625 11.6133 7.75586 11.8281C7.94922 12.043 7.94922 12.3652 7.73438 12.5586Z"
      fill="#F8F8F8"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 8,
  })
</script>

<style lang="scss" scoped>
  $module: 'up-down-chevrons-icon';

  .#{$module} {
    display: block;

    path {
      stroke: $grey-2;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
