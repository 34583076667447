export enum FRANCHISE_EMISSION_SCOPE {
  SCOPE_1_2 = 'scope12',
  SCOPE_1_2_3 = 'scope123',
}

export enum FRANCHISE_REQUEST_STATUS {
  IN_PROGRESS = 'in_progress',
  REVIEW_PENDING = 'review_pending',
  REVIEW_REJECTED = 'review_rejected',
  INGESTION_PENDING = 'ingestion_pending',
  INGESTION_FAILED = 'ingestion_failed',
  INGESTION_COMPLETED = 'ingestion_completed',
}

export enum FRANCHISE_STATUS {
  NOT_INVITED = 'invite_not_sent',
  INVITE_PENDING = 'invite_pending',
  IN_PROGRESS = FRANCHISE_REQUEST_STATUS.IN_PROGRESS,
  REVIEW_PENDING = FRANCHISE_REQUEST_STATUS.REVIEW_PENDING,
  REVIEW_REJECTED = FRANCHISE_REQUEST_STATUS.REVIEW_REJECTED,
  INGESTION_PENDING = FRANCHISE_REQUEST_STATUS.INGESTION_PENDING,
  INGESTION_FAILED = FRANCHISE_REQUEST_STATUS.INGESTION_FAILED,
  INGESTION_COMPLETED = FRANCHISE_REQUEST_STATUS.INGESTION_COMPLETED,
}

export enum GET_FRANCHISES_SORT_FIELD {
  STATUS = 'status',
  LATEST_TOTAL_EMISSIONS = 'latestTotalEmissions',
  DATA_QUALITY_SCORE = 'dataQualityScore',
  NAME = 'name',
  COUNTRY_CODE = 'countryCode',
}

export enum DRAWER_TYPES {
  ADD = 'add',
  EDIT = 'edit',
}

export enum GET_RESPONDED_FRANCHISES_SORT_FIELD {
  NAME = 'name',
  SCOPE_ONE = 'scopeOne',
  SCOPE_TWO = 'scopeTwo',
  SCOPE_THREE = 'scopeThree',
}

export enum FRANCHISE_UPLOAD_TYPE {
  FRANCHISE_SURVEY = 'franchise_survey',
  FRANCHISE_CALCULATOR = 'franchise_calculator',
  FRANCHISE_ESTIMATION = 'franchise_estimation',
}

export enum FRANCHISE_UPLOAD_STATUS {
  IN_PROGRESS = 'in_progress',
  PUBLISHED = 'published',
  INGESTED = 'ingested',
  INGESTION_ERROR = 'ingestion_error',
}
