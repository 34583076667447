import ManageDataIcon from '@components/icons/ManageDataIcon.vue'
import AnalyseIcon from '@components/icons/AnalyseIcon.vue'
import EngageSuppliersIcon from '@components/icons/EngageSuppliersIcon.vue'
import ReportIcon from '@components/icons/ReportIcon.vue'
import DashboardIcon from '@components/icons/DashboardIcon.vue'

import type { NavGroup } from '@/imports/@types/Navigation'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

export const getSupplierNav = (): NavGroup[] => [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    to: {
      name: 'dashboard',
    },
  },
  {
    label: 'Manage Data',
    icon: ManageDataIcon,
    children: [
      {
        label: 'Corporate Footprints',
        to: {
          name: 'supplierFootprints',
        },
        featureFlag: 'enable_supplier_footprints',
      },
      {
        label: 'Product Footprints',
        to: {
          name: 'supplierProducts',
        },
        featureFlag: 'enable_supplier_product_footprint',
      },
      {
        label: 'Legal Entities',
        to: {
          name: 'legalEntities',
        },
        featureFlag: 'enable_legal_entities',
      },
      {
        label: 'Processing Data',
        to: {
          name: 'processingData',
        },
        featureFlag: 'rollout-standalone-pcf-37955',
      },
    ],
  },
  {
    label: 'Analyse',
    icon: AnalyseIcon,
    to: {
      name: 'measure',
    },
  },
  {
    label: 'Engage Partners',
    icon: EngageSuppliersIcon,
    children: [
      {
        label: 'Customers',
        to: {
          name: 'customersTab',
        },
        featureFlag: 'enable_customers_engagement_page',
      },

      {
        label: 'Franchisors',
        to: {
          name: 'franchisors',
        },
        featureFlag: 'rollout-franchisor-engagement',
      },
    ],
  },
  {
    label: 'Reports',
    icon: ReportIcon,
    to: {
      name: 'share',
    },
  },
]

export const getProductFootPrintStartTierNav = (): NavGroup[] => [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    to: {
      name: 'dashboard',
    },
  },
  {
    label: 'Manage Data',
    icon: ManageDataIcon,
    children: [
      {
        label: 'Product Footprints',
        to: {
          name: 'supplierProducts',
        },
        featureFlag: 'enable_supplier_product_footprint',
      },
      {
        label: 'Product Data',
        to: {
          name: 'ProductsUpload',
        },
        meta: {
          requiredOrgModule: ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
        },
      },

      {
        label: 'Processing Data',
        to: {
          name: 'processingData',
        },
        featureFlag: 'rollout-standalone-pcf-37955',
      },
    ],
  },
  {
    label: 'Analyse',
    icon: AnalyseIcon,
    children: [
      {
        label: 'Products',
        to: {
          name: 'products',
        },
        meta: {
          requiredOrgModule: ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
        },
      },
    ],
  },
  {
    label: 'Engage Customers',
    icon: EngageSuppliersIcon,
    featureFlag: 'enable_customers_engagement_page',
    to: {
      name: 'customersEngagement',
    },
  },
]
