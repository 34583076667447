<template>
  <div class="beta-banner">
    <span>
      BETA: This is a new feature -
      <a
        class="beta-banner__feedback-link"
        @click="openIntercom"
      >
        your feedback
      </a>
      will help us improve it
    </span>

    <tippy>
      <i class="fa-regular fa-circle-info" />
      <template v-slot:content>
        This feature is in beta release. We’re working on adding more functionality and developing the experience. If
        you have feedback or questions, please send us a message via the "Ask your advisor" help chat.
      </template>
    </tippy>
  </div>
</template>

<script lang="ts" setup>
  const openIntercom = () => {
    window.Intercom('show')
  }
</script>

<style lang="scss" scoped>
  $module: 'beta-banner';

  .#{$module} {
    width: 100%;
    display: flex;
    padding: $grid-size-padding * 2 $grid-size-padding * 4;
    align-items: center;
    gap: $grid-size-padding;
    font-size: $font-size-7;
    font-weight: 500;
    color: $kelp-dark;
    background-color: #e8eeef;
    border-bottom: 1px solid #d6e3e4;

    &__feedback-link {
      text-decoration: underline;
    }
  }
</style>
