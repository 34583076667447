import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

import { DATA_SOURCE_TARGET_MODULE } from '@/imports/@enums/data-source.enums'

export const PRODUCT_DATA_SOURCE_GET_SUBPATH = 'product/find-or-create'
export const PRODUCT_DATA_SOURCE_GET_URI = `${DATASOURCE_CONTROLLER}/${PRODUCT_DATA_SOURCE_GET_SUBPATH}`

export class GetProductDataSourceDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetProductDataSourceDTORes = {
  dataSource: {
    id: string
    status: string
    calculationMethods: string[]
    assumptions: string[]
    isCustomCalculation: boolean
    orgId: string
    name: string
    startDate: string
    endDate: string | null
    updateFrequency: string
    type: string
    lookupName: string
    description: string
    targetModule: DATA_SOURCE_TARGET_MODULE
    updatedAt: string
    createdAt: string
    asanaTaskId: string
    labelStudioProjectId: string
    deletedAt: string
  }
}
