// eslint-disable-next-line max-classes-per-file
import { IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'

import { BASIC_CALCULATION_FORM_STATUSES } from '../../../@enums/basic-calculations.enums'

export const BASIC_PCF_CALCULATIONS_CONTROLLER = 'basic-pcf'

export class TotalProductQuantityRecord {
  @IsUUID()
  facilityId!: string

  @IsNumber()
  totalProductQuantity!: number

  @IsString()
  totalProductUnit!: string

  @IsBoolean()
  isEstimatedAmount!: boolean
}

export class TotalProductQuantity {
  @IsEnum(BASIC_CALCULATION_FORM_STATUSES)
  status!: BASIC_CALCULATION_FORM_STATUSES

  @IsString()
  calcMethodName!: string

  @IsString()
  subActivityCode!: string

  @IsArray()
  @ValidateNested()
  @Type(() => TotalProductQuantityRecord)
  facilityRecords!: TotalProductQuantityRecord[]
}

export class BasicCalculationRecord {
  @IsString()
  inputType!: string

  @IsNumber()
  amount!: number

  @IsBoolean()
  isEstimatedAmount!: boolean

  @IsString()
  unit!: string

  @IsEnum(BASIC_CALCULATION_FORM_STATUSES)
  @IsOptional()
  status?: BASIC_CALCULATION_FORM_STATUSES
}

export class BasicCalculationFacilityRecord {
  @IsString()
  facilityId!: string

  @IsEnum(BASIC_CALCULATION_FORM_STATUSES)
  status!: BASIC_CALCULATION_FORM_STATUSES

  @IsArray()
  @ValidateNested()
  @Type(() => BasicCalculationRecord)
  records!: BasicCalculationRecord[]
}

export class BasicCalculationFacilityForm {
  @IsEnum(BASIC_CALCULATION_FORM_STATUSES)
  status!: BASIC_CALCULATION_FORM_STATUSES

  @IsString()
  subActivityCode!: string

  @IsString()
  calcMethodName!: string

  @IsArray()
  @ValidateNested()
  @Type(() => BasicCalculationFacilityRecord)
  facilityRecords!: BasicCalculationFacilityRecord[]
}

export class FacilitiesForms {
  @IsArray()
  @ValidateNested()
  @Type(() => BasicCalculationFacilityForm)
  activities: BasicCalculationFacilityForm[]

  @ValidateNested()
  @Type(() => TotalProductQuantity)
  totalProductQuantity: TotalProductQuantity
}
