import type { AxiosInstance } from 'axios'

import {
  SUBMIT_BASIC_PCF_CACHE_URI,
  type SubmitBasicPCFCacheRes,
} from '@lib/DTO/basic-pcf-calculations/submit-basic-pcf-calculation-cache.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import {
  CREATE_BASIC_PCF_CACHE_URI,
  type CreateBasicPCFCacheRes,
} from '@/imports/lib/DTO/basic-pcf-calculations/create-basic-pcf-calculation-cache.dto'
import { replaceUriParams } from '@/imports/lib/DTO'
import {
  GET_BASIC_PCF_CACHE_URI,
  type GetBasicPCFCacheRes,
} from '@/imports/lib/DTO/basic-pcf-calculations/get-basic-pcf-calculation-cache.dto'
import {
  UPDATE_BASIC_PCF_CACHE_URI,
  type UpdateBasicPCFCacheRes,
} from '@/imports/lib/DTO/basic-pcf-calculations/update-basic-pcf-calculation-cache.dto'
import type { TProductBasicCalculatorFacilities } from '@/imports/@types/basicCalculator/ProductBasicCalculator'

export const useProductBasicCalculatorApi = (http: AxiosInstance) => ({
  createBasicCalculatorCache(year: number) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.post<CreateBasicPCFCacheRes>(
      `/${CREATE_BASIC_PCF_CACHE_URI}`,
      { year },
      {
        headers: {
          'x-org-id': orgStore.activeOrganization.id,
        },
      },
    )
  },

  getBasicCalculatorWizard(year: number) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    const url = replaceUriParams(GET_BASIC_PCF_CACHE_URI, { year })
    return http.get<GetBasicPCFCacheRes>(url, {
      headers: {
        'x-org-id': orgStore.activeOrganization.id,
      },
    })
  },

  updateBasicCalculatorWizard(id: string, facilitiesForms: TProductBasicCalculatorFacilities) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    const url = replaceUriParams(UPDATE_BASIC_PCF_CACHE_URI, { cacheId: id })
    return http.put<UpdateBasicPCFCacheRes>(
      url,
      { facilitiesForms },
      {
        headers: {
          'x-org-id': orgStore.activeOrganization.id,
        },
      },
    )
  },

  submitBasicCalculatorWizard(id: string, facilitiesForms: TProductBasicCalculatorFacilities, currency: string) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    const url = replaceUriParams(SUBMIT_BASIC_PCF_CACHE_URI, { cacheId: id })
    return http.post<SubmitBasicPCFCacheRes>(
      url,
      { facilitiesForms, currency },
      {
        headers: {
          'x-org-id': orgStore.activeOrganization.id,
        },
      },
    )
  },
})
