<template>
  <svg
    class="tag-icon"
    :width="width"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.6875 5.125C3.6875 4.61719 4.07812 4.1875 4.625 4.1875C5.13281 4.1875 5.5625 4.61719 5.5625 5.125C5.5625 5.67188 5.13281 6.0625 4.625 6.0625C4.07812 6.0625 3.6875 5.67188 3.6875 5.125ZM0.25 2.625C0.25 1.60938 1.07031 0.75 2.125 0.75H7.94531C8.60938 0.75 9.23438 1.02344 9.70312 1.49219L16.5781 8.36719C17.5547 9.34375 17.5547 10.9453 16.5781 11.9219L11.3828 17.1172C10.4062 18.0938 8.80469 18.0938 7.82812 17.1172L0.953125 10.2422C0.484375 9.77344 0.25 9.14844 0.25 8.48438V2.625ZM1.85156 9.38281L8.72656 16.2578C9.19531 16.7266 10.0156 16.7266 10.4844 16.2578L15.7188 11.0234C16.1875 10.5547 16.1875 9.73438 15.7188 9.26562L8.84375 2.39062C8.60938 2.15625 8.25781 2 7.94531 2H2.125C1.77344 2 1.5 2.3125 1.5 2.625V8.48438C1.5 8.79688 1.61719 9.14844 1.85156 9.38281ZM1.85156 9.38281L0.953125 10.2422L1.85156 9.38281Z"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'files-icon';

  .#{$module} {
    display: block;

    path {
      stroke-width: 0.3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
