// eslint-disable-next-line max-classes-per-file
import { ArrayMaxSize, IsArray, IsEmail, IsNumber, IsString, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class UnknownSuppliersDetails {
  @IsString()
  supplierHash!: string

  @IsEmail()
  inviteEmail!: string
}

export class KnownSuppliersDetails {
  @IsString()
  orgId!: string
}

export class CreateInvitesDTO {
  @IsNumber()
  year!: number

  @IsArray()
  @ArrayMaxSize(500)
  @Type(() => UnknownSuppliersDetails)
  @ValidateNested()
  unknownSuppliers!: UnknownSuppliersDetails[]

  @IsArray()
  @ArrayMaxSize(500)
  @Type(() => KnownSuppliersDetails)
  @ValidateNested()
  knownSuppliers!: KnownSuppliersDetails[]

  @IsString()
  personalMessage!: string
}

export const CREATE_BULK_SUPPLIER_CORPORATE_INVITES_SUB_PATH = 'invite/bulk'
export const CREATE_BULK_SUPPLIER_CORPORATE_INVITES_PATH = `${SUPPLIERS_CONTROLLER}/${CREATE_BULK_SUPPLIER_CORPORATE_INVITES_SUB_PATH}`
