// Get interactive dashboard URI

import { IsEnum } from 'class-validator'

import { ValidateDTO } from '..'

import {
  ANALYTICS_CONTROLLER,
  type AnalyticsIframeFormatting,
  ANALYTICS_DASHBOARD_MAPPING,
} from './controller.common-vars'

export const ANALYTICS_INTERACTIVE_SUBPATH = 'interactive'
export const ANALYTICS_INTERACTIVE_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_INTERACTIVE_SUBPATH}`

export class AnalyticsInteractiveDashboardDTOReq extends ValidateDTO {
  @IsEnum(ANALYTICS_DASHBOARD_MAPPING)
  section!: ANALYTICS_DASHBOARD_MAPPING

  formatting?: AnalyticsIframeFormatting

  redirectTo?: string
}

export type AnalyticsInteractiveDashboardDTORes = {
  uri: string
}
