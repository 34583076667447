import PuzzlePieceIcon from '@components/icons/PuzzlePieceIcon.vue'
import FilesIcon from '@components/icons/FilesIcon.vue'
import TagIcon from '@components/icons/TagIcon.vue'
import AbacusIcon from '@components/icons/AbacusIcon.vue'
import GridIcon from '@components/icons/GridIcon.vue'

import type { NavGroup } from '@/imports/@types/Navigation'

export const getInternalUserNav = (): NavGroup[] => [
  {
    label: 'AQ Advisor',
    icon: PuzzlePieceIcon,
    children: [
      {
        label: 'Overview',
        to: {
          name: 'AQWelcome',
        },
      },
      {
        label: 'EF Catalogue',
        to: {
          name: 'EFCatalogue',
        },
        featureFlag: 'rollout-upload-emission-factors',
      },
      {
        label: 'AI Invoice Analyser',
        to: {
          name: 'PDFPageGrouping',
        },
        featureFlag: 'rollout-pdf-v2',
      },

      {
        label: 'Tagging',
        to: {
          name: 'Tagging',
        },
      },

      {
        label: 'Your Calculations',
        to: {
          name: 'Calculations',
        },
      },
    ],
  },
]

export const getAdvisorAppNav = (): NavGroup[] => [
  {
    label: 'EF Catalogue',
    icon: GridIcon,
    to: {
      name: 'EFCatalogue',
    },
    featureFlag: 'rollout-upload-emission-factors',
  },
  {
    label: 'AI Invoice Analyser',
    icon: FilesIcon,
    to: {
      name: 'PDFPageGrouping',
    },
    featureFlag: 'rollout-pdf-v2',
  },
  {
    label: 'Tagging',
    icon: TagIcon,
    to: {
      name: 'Tagging',
    },
  },
  {
    label: 'Your Calculations',
    icon: AbacusIcon,
    to: {
      name: 'Calculations',
    },
  },
]
