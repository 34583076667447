import { IsString } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

// Snapshots page facility dropdown list
// [GET] /products/:productId/facilities

export const PRODUCT_FACILITIES_OVERVIEW_SUBPATH = ':productId/facilities'
export const PRODUCT_FACILITIES_OVERVIEW_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_FACILITIES_OVERVIEW_SUBPATH}`

export class ProductFacilitiesDTOReq extends ValidateDTO {
  @IsString()
  productId!: string

  startMonth!: string

  endMonth!: string
}

export type ProductFacilities = {
  facilityId: string
  facilityName: string
  countryCode: string
}

export type ProductBUs = {
  orgId: string
  orgName: string
  logo: string
}

export type ProductFacilitiesDTORes = {
  facilities: ProductFacilities[]
  BUs: ProductBUs[]
}
