import moment from 'moment'

import FeatureFlagService from '@lib/services/featureFlagService'

import type { OrgCompletedYearStatus } from '@/imports/@types/corporateFootprints/CorporateFootprint'

export const getFirstUnlockedDate = (
  completedYearStatuses: OrgCompletedYearStatus[],
  baselineYear: number | undefined,
  usesCalendarYear: boolean,
  financialYearStartsAt?: string,
) => {
  if (!baselineYear) return

  const baselineYearComputed = !usesCalendarYear ? baselineYear - 1 : baselineYear
  const calendarYearMoment = moment(financialYearStartsAt)
  const earliestMonth = financialYearStartsAt ? calendarYearMoment.month() : 0
  const earliestDate = financialYearStartsAt ? calendarYearMoment.date() : 1

  const latestYearObj = completedYearStatuses.reduce((maxYearObj, currentYearObj) => {
    if (!maxYearObj.year && currentYearObj.isComplete) {
      return currentYearObj
    }

    return currentYearObj.year > maxYearObj.year && currentYearObj.isComplete ? currentYearObj : maxYearObj
  }, {} as OrgCompletedYearStatus)

  if (latestYearObj?.year) {
    return new Date(+latestYearObj.year + (usesCalendarYear ? 1 : 0), earliestMonth, earliestDate)
  }

  return new Date(baselineYearComputed, earliestMonth, earliestDate)
}

export const isProductCarbonFootprintStandAloneEnabled = () => {
  return FeatureFlagService.isEnabled('rollout-standalone-pcf-37955')
}
