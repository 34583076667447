export const ANALYTICS_CONTROLLER = 'analytics'

export enum ANALYTICS_DASHBOARD_MAPPING {
  OUR_ANALYTICS = '%2Fcollection%2Froot',
  COLLECTIONS = '%2Fcollection%2F',
  BROWSE_DATA = '%2Fbrowse',
  HOME = '%2F',
}

export type AnalyticsIframeFormatting = {
  showTopNav?: boolean

  showSideNav?: boolean

  showBorder?: boolean

  showTitle?: boolean
}

export type MetabaseJWTPayload = {
  exp: number
}

export type MetabaseStaticDashboardJWTPayload = MetabaseJWTPayload & {
  resource: {
    dashboard: number
  }

  params: Record<string, string | number>
}

export type MetabaseInteractiveDashboardJWTPayload = MetabaseJWTPayload & {
  email: string

  first_name?: string

  last_name?: string

  groups: string[]

  org_id: string[]

  iat: number
}

export enum METABASE_EMBED_TYPE {
  DASHBOARD = 'dashboard',
  QUESTION = 'question',
}
