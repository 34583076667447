// Product emissions by activity
// [GET] /product/:productId/metric-activity-emissions?startMonth=01-2022&endMonth=12-2022
import { IsString } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_METRIC_ACTIVITY_EMISSIONS_SUBPATH = ':productId/metric-activity-emissions'
export const PRODUCT_METRIC_ACTIVITY_EMISSIONS_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_METRIC_ACTIVITY_EMISSIONS_SUBPATH}`

export class ProductEmissionsByActivityDTOReq {
  startMonth!: string

  endMonth!: string

  @IsString()
  productId!: string
}

export type EmissionByActivityArea = {
  activityAreaName: string
  factor: number
  emissions: number
  emissionUnit: string // changed from emissionsUnit
}

export type ProductEmissionsByActivityDTORes = {
  emissionsByActivityArea: EmissionByActivityArea[] // sorted by factor
}
