<template>
  <i :class="['icon', pack, `fa-${icon}`, size]" />
</template>

<script lang="ts" setup>
  type Props = {
    pack?: string
    icon: string
    size?: string
  }

  withDefaults(defineProps<Props>(), {
    pack: 'far',
    icon: '',
    size: 'medium',
  })
</script>

<style lang="scss" scoped>
  $module: 'icon';

  $radius: 4px;

  .#{$module} {
    display: inline-flex;

    &.is-small {
      font-size: 8px;
    }

    &.is-large {
      font-size: 28px;
    }
  }
</style>
