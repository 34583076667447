<template>
  <svg
    class="files-icon"
    :width="width"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.375 19.25C13.6875 19.25 14 19.5625 14 19.875C14 20.2266 13.6875 20.5 13.375 20.5H4C1.89062 20.5 0.25 18.8594 0.25 16.75V4.875C0.25 4.5625 0.523438 4.25 0.875 4.25C1.1875 4.25 1.5 4.5625 1.5 4.875V16.75C1.5 18.1562 2.59375 19.25 4 19.25H13.375ZM17.0078 4.95312C17.4766 5.42188 17.75 6.04688 17.75 6.71094V14.25C17.75 15.6562 16.6172 16.75 15.25 16.75H6.5C5.09375 16.75 4 15.6562 4 14.25V3C4 1.63281 5.09375 0.5 6.5 0.5H11.5391C12.2031 0.5 12.8281 0.773438 13.2969 1.24219L17.0078 4.95312ZM12.75 2.45312V4.875C12.75 5.22656 13.0234 5.5 13.375 5.5H15.7969L12.75 2.45312ZM16.5 14.25V6.75H13.375C12.3203 6.75 11.5 5.92969 11.5 4.875V1.75H6.5C5.79688 1.75 5.25 2.33594 5.25 3V14.25C5.25 14.9531 5.79688 15.5 6.5 15.5H15.25C15.9141 15.5 16.5 14.9531 16.5 14.25Z"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'files-icon';

  .#{$module} {
    display: block;
    fill: $grey-8;

    path {
      stroke-width: 0.3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
