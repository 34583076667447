import type { AxiosInstance } from 'axios'

import {
  PRODUCT_FACILITY_DETAILS_URI,
  type ProductEmissionsPerFacilityDTOReq,
  type ProductEmissionsPerFacilityDTORes,
} from '@lib/DTO/products/details/product-per-facility.dto'

import {
  type TotalProductCarbonImpactDTORes,
  type TotalProductCarbonImpactDTOReq,
  PRODUCT_OVERVIEW_METRIC_IMPACT_URI,
} from '@lib/DTO/products/overview/metric-impact.dto'

import {
  type HighestEmittingProductCategoryDTORes,
  type HighestEmittingProductCategoryDTOReq,
  PRODUCT_OVERVIEW_HIGHEST_CAT_URI,
} from '@lib/DTO/products/overview/metric-highest-category.dto'

import {
  type HighestEmittingProductDTORes,
  type HighestEmittingProductDTOReq,
  PRODUCT_OVERVIEW_HIGHEST_PRODUCT_URI,
} from '@lib/DTO/products/overview/metric-highest-product.dto'

import {
  type ProductListDTORes,
  type ProductListDTOReq,
  PRODUCT_OVERVIEW_URI,
} from '@lib/DTO/products/overview/product-list.dto'

import {
  type CategoryListDTORes,
  type CategoryListDTOReq,
  PRODUCT_CATEGROGIES_OVERVIEW_URI,
} from '@lib/DTO/products/overview/category-list.dto'

import { replaceUriParams } from '@lib/DTO'

import {
  GET_IS_CALCULATING_PRODUCTS_URI,
  type ProductsIsCalculatingRes,
} from '../../DTO/products/get-is-product-calculating.dto'

import { isProductCarbonFootprintStandAloneEnabled } from '../../utilities/orgStore/orgStore.utils'

import {
  type CategoryNamesDTOReq,
  type CategoryNamesDTORes,
  PRODUCT_CATEGORIES_NAMES_URI,
} from '@/imports/lib/DTO/products/overview/category-names.dto'

import {
  PRODUCT_METRIC_ACTIVITY_EMISSIONS_URI,
  type ProductEmissionsByActivityDTOReq,
  type ProductEmissionsByActivityDTORes,
} from '@/imports/lib/DTO/products/details/metric-activity-emissions.dto'

import {
  type HighestProductFacilityEmissionsDTOReq,
  type HighestProductFacilityEmissionsDTORes,
  PRODUCT_METRIC_HIGHEST_FACILITY_URI,
} from '@/imports/lib/DTO/products/details/metric-highest-facility.dto'

import {
  PRODUCT_METRIC_CARBON_IMPACT_URI,
  type ProductTotalImpactDTOReq,
  type ProductTotalImpactDTORes,
} from '@/imports/lib/DTO/products/details/metric-carbon-impact.dto'

import {
  type ProductInfoDTORes,
  type ProductInfoDTOReq,
  PRODUCT_INFO_URI,
} from '@/imports/lib/DTO/products/details/product-info.dto'

import {
  type ActivityNamesDTOReq,
  type ActivityNamesDTORes,
  PRODUCT_ACTIVITY_DETAIL_URI,
} from '@/imports/lib/DTO/products/details/activity-names.dto'
import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { Product, ProductWithFootprint } from '@/imports/@types/Product'
import type { ProductCategory } from '@/imports/@types/ProductCategory'
import type {
  Material,
  MaterialDetail,
  MaterialDetailRequestPayload,
  MaterialsRequestPayload,
} from '@/imports/@types/ProductMaterial'
import {
  PRODUCT_PARTS_URI,
  type ProductPartsDTOReq,
  type ProductPartsRes,
} from '@/imports/lib/DTO/products/details/product-parts.dto'

import {
  type SnapshotDetailsDTORes,
  type SnapshotDetailsDTOReq,
  PRODUCT_SNAPSHOT_FACILITY_URI,
  PRODUCT_SNAPSHOT_BU_URI,
} from '@/imports/lib/DTO/products/snapshot/snapshot-details.dto'

import {
  type ProductFacilitiesDTORes,
  type ProductFacilitiesDTOReq,
  PRODUCT_FACILITIES_OVERVIEW_URI,
} from '@/imports/lib/DTO/products/details/facility-overview.dto'

import {
  type ProductSnapshotsDTORes,
  type ProductSnapshotsDTOReq,
  PRODUCT_SNAPSHOT_FACILITIES_LIST_URI,
  PRODUCT_SNAPSHOT_BUS_LIST_URI,
} from '@/imports/lib/DTO/products/snapshot/snapshot-list.dto'

export class ProductsApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getProducts(orgId: string) {
    return this.http.get<ApiResponseInner<{ products: Product[] }>>('/products/list', {
      params: {
        orgId,
      },
    })
  }

  getMetricImpact(orgId: string, metricImpactPayload: TotalProductCarbonImpactDTOReq) {
    return this.http.get<TotalProductCarbonImpactDTORes>(PRODUCT_OVERVIEW_METRIC_IMPACT_URI, {
      params: {
        ...metricImpactPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getMetricCategory(orgId: string, metricCategoryPayload: HighestEmittingProductCategoryDTOReq) {
    return this.http.get<HighestEmittingProductCategoryDTORes>(PRODUCT_OVERVIEW_HIGHEST_CAT_URI, {
      params: {
        ...metricCategoryPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getMetricHighestEmittingProduct(orgId: string, metricHighestEmittingProductPayload: HighestEmittingProductDTOReq) {
    return this.http.get<HighestEmittingProductDTORes>(PRODUCT_OVERVIEW_HIGHEST_PRODUCT_URI, {
      params: {
        ...metricHighestEmittingProductPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductsV2(orgId: string, productsPayload: ProductListDTOReq) {
    return this.http.get<ProductListDTORes>(PRODUCT_OVERVIEW_URI, {
      params: {
        ...productsPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductFootprint({ productId, orgId }: { productId: string; orgId: string }) {
    return this.http.get<ApiResponseInner<{ product: ProductWithFootprint }>>('/products/get', {
      params: {
        productId,
        orgId,
      },
    })
  }

  getCategoriesV2(orgId: string, categoriesPayload: CategoryListDTOReq) {
    return this.http.get<CategoryListDTORes>(PRODUCT_CATEGROGIES_OVERVIEW_URI, {
      params: {
        ...categoriesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getCategoryNames(orgId: string, categoryNamesPayload: CategoryNamesDTOReq) {
    return this.http.get<CategoryNamesDTORes>(PRODUCT_CATEGORIES_NAMES_URI, {
      params: {
        ...categoryNamesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getActivityNames(orgId: string, activityNamesPayload: ActivityNamesDTOReq) {
    return this.http.get<ActivityNamesDTORes>(replaceUriParams(PRODUCT_ACTIVITY_DETAIL_URI, activityNamesPayload), {
      params: {
        ...activityNamesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getCategories(orgId: string) {
    return this.http.get<ApiResponseInner<{ categories: ProductCategory[] }>>('/products/getCategories', {
      params: {
        orgId,
      },
    })
  }

  getMaterials(materialsRequestPayload: MaterialsRequestPayload) {
    return this.http.get<ApiResponseInner<Material[]>>('/products/materials', {
      params: {
        ...materialsRequestPayload,
      },
    })
  }

  getMaterialsDetails(materialDetailRequestPayload: MaterialDetailRequestPayload) {
    return this.http.get<ApiResponseInner<MaterialDetail>>('/products/materials/:id', {
      params: {
        ...materialDetailRequestPayload,
      },
    })
  }

  getDataSourceTemplate(orgId: string) {
    return this.http.get<ApiResponseInner<unknown>>('/products/getdatasourcetemplate', {
      params: {
        orgId,
      },
    })
  }

  // Product footprint v2 work
  getProductDetails({ orgId, productId, startMonth, endMonth }: ProductInfoDTOReq & { orgId: string }) {
    return this.http.get<ProductInfoDTORes>(replaceUriParams(PRODUCT_INFO_URI, { productId }), {
      params: {
        startMonth,
        endMonth,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductTotalCarbonImpact({
    productId,
    startMonth,
    endMonth,
    orgId,
    facilityId,
    buId,
  }: ProductTotalImpactDTOReq & { orgId: string }) {
    return this.http.get<ProductTotalImpactDTORes>(replaceUriParams(PRODUCT_METRIC_CARBON_IMPACT_URI, { productId }), {
      params: {
        startMonth,
        endMonth,
        facilityId,
        buId,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductTopFacilityEmissions({
    productId,
    startMonth,
    endMonth,
    orgId,
  }: HighestProductFacilityEmissionsDTOReq & { orgId: string }) {
    return this.http.get<HighestProductFacilityEmissionsDTORes>(
      replaceUriParams(PRODUCT_METRIC_HIGHEST_FACILITY_URI, { productId }),
      {
        params: {
          startMonth,
          endMonth,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getProductEmissionsByActivity({
    productId,
    startMonth,
    endMonth,
    orgId,
  }: ProductEmissionsByActivityDTOReq & { orgId: string }) {
    return this.http.get<ProductEmissionsByActivityDTORes>(
      replaceUriParams(PRODUCT_METRIC_ACTIVITY_EMISSIONS_URI, { productId }),
      {
        params: {
          startMonth,
          endMonth,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getEmissionsPerFacility(orgId: string, emissionsPerFacilityPayload: ProductEmissionsPerFacilityDTOReq) {
    return this.http.get<ProductEmissionsPerFacilityDTORes>(
      replaceUriParams(PRODUCT_FACILITY_DETAILS_URI, emissionsPerFacilityPayload),
      {
        params: {
          ...emissionsPerFacilityPayload,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getMaterialsAndPackaging(orgId: string, materialsAndPackagingPayload: ProductPartsDTOReq) {
    return this.http.get<ProductPartsRes>(replaceUriParams(PRODUCT_PARTS_URI, materialsAndPackagingPayload), {
      params: materialsAndPackagingPayload,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductFacilitySnapshotDetails(orgId: string, productSnapshotPayload: SnapshotDetailsDTOReq) {
    return this.http.get<SnapshotDetailsDTORes>(
      replaceUriParams(PRODUCT_SNAPSHOT_FACILITY_URI, productSnapshotPayload),
      {
        params: {
          ...productSnapshotPayload,
          includeBasicPCFStatuses: isProductCarbonFootprintStandAloneEnabled(),
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getProductBUSnapshotDetails(orgId: string, productSnapshotPayload: SnapshotDetailsDTOReq) {
    return this.http.get<SnapshotDetailsDTORes>(replaceUriParams(PRODUCT_SNAPSHOT_BU_URI, productSnapshotPayload), {
      params: {
        ...productSnapshotPayload,
        includeBasicPCFStatuses: isProductCarbonFootprintStandAloneEnabled(),
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductFacilitySnapshots(orgId: string, productSnapshotsPayload: ProductSnapshotsDTOReq) {
    return this.http.get<ProductSnapshotsDTORes>(
      replaceUriParams(PRODUCT_SNAPSHOT_FACILITIES_LIST_URI, productSnapshotsPayload),
      {
        params: {
          ...productSnapshotsPayload,
          includeBasicPCFStatus: isProductCarbonFootprintStandAloneEnabled(),
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getProductBUSnapshots(orgId: string, productSnapshotsPayload: ProductSnapshotsDTOReq) {
    return this.http.get<ProductSnapshotsDTORes>(
      replaceUriParams(PRODUCT_SNAPSHOT_BUS_LIST_URI, productSnapshotsPayload),
      {
        params: {
          ...productSnapshotsPayload,
          includeBasicPCFStatus: isProductCarbonFootprintStandAloneEnabled(),
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getFacilities(orgId: string, productFacilitiesPayload: ProductFacilitiesDTOReq) {
    return this.http.get<ProductFacilitiesDTORes>(
      replaceUriParams(PRODUCT_FACILITIES_OVERVIEW_URI, productFacilitiesPayload),
      {
        params: {
          ...productFacilitiesPayload,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  isCalculatingProducts(orgId: string) {
    return this.http.get<ProductsIsCalculatingRes>(GET_IS_CALCULATING_PRODUCTS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })
  }
}
