import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_IS_CALCULATING_PRODUCTS_SUBPATH = 'is-calculating'
export const GET_IS_CALCULATING_PRODUCTS_URI = `${PRODUCTS_CONTROLLER}/${GET_IS_CALCULATING_PRODUCTS_SUBPATH}`

export class ProductsIsCalculatingDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type ProductsIsCalculatingRes = {
  isCalculating: boolean
}
