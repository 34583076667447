// Highest emitting product
// [GET] /products/overview/metric-highest-product?orgId=xxx&startMonth=01-2022&endMonth=12-2022

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_OVERVIEW_HIGHEST_PRODUCT_SUBPATH = 'overview/metric-highest-product'
export const PRODUCT_OVERVIEW_HIGHEST_PRODUCT_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_OVERVIEW_HIGHEST_PRODUCT_SUBPATH}`

export class HighestEmittingProductDTOReq {
  startMonth!: string

  endMonth!: string
}

export type HighestEmittingProductDTORes = {
  productName: string
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
}
