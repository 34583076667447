// eslint-disable-next-line max-classes-per-file
import { IsArray, IsNumber, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'

import { FRANCHISE_ESTIMATIONS_CONTROLLER } from './franchise-estimations.common-vars'

export class FranchiseEstimationDTO {
  @IsNumber()
  estimation: number

  @IsUUID()
  franchiseId: string

  @IsString()
  franchiseName: string

  @IsUUID()
  @IsOptional()
  franchiseOrgId?: string
}

export class AddFranchiseEstimationPropertiesDTO {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => FranchiseEstimationDTO)
  franchiseEstimations: FranchiseEstimationDTO[]

  @IsNumber()
  year: number
}

export const ADD_FRANCHISE_ESTIMATIONS_SUB_PATH = ''
export const ADD_FRANCHISE_ESTIMATIONS_PATH = `${FRANCHISE_ESTIMATIONS_CONTROLLER}/${ADD_FRANCHISE_ESTIMATIONS_SUB_PATH}`
