<template>
  <div class="container">
    <AltruistiqPublicLogo></AltruistiqPublicLogo>
    <div class="background-image"></div>
  </div>
</template>

<script lang="ts" setup>
  import AltruistiqPublicLogo from '@/imports/ui/components/AltruistiqPublicLogo.vue'
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/windmill-image.jpg');
    background-position: center center;
    background-size: cover;
  }
</style>
