import { IsOptional, IsNumber } from 'class-validator'

import { ValidateDTO } from '..'

import { BASIC_FACILITIES_CONTROLLER } from './controller.common-vars'

import { BASIC_FACILITY_DATA_STATUS } from '@/imports/@enums/basic-facilities.enums'

export const BASIC_FACILITY_DETAIL_SUBPATH = ':facilityId'
export const BASIC_FACILITY_DETAIL_URI = `${BASIC_FACILITIES_CONTROLLER}/${BASIC_FACILITY_DETAIL_SUBPATH}`

export class BasicFacilityDetailDTOReq extends ValidateDTO {
  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsNumber()
  @IsOptional()
  year?: number
}

export type ProcessingSubActivityData = {
  subActivityName: string
  subActivityId: number
  quantity: number | null
  unit: string | null
}

export type ProcessingActivityData = {
  activityName: string
  activityId: number
  subActivities: ProcessingSubActivityData[]
}
export type ProcessingActivityAreaData = {
  activityAreaName: string
  activityAreaId: number
  activities: ProcessingActivityData[]
}

export type ProcessingData = {
  status: BASIC_FACILITY_DATA_STATUS
  year: number
  totalProductQuantity: number
  activities: ProcessingActivityAreaData[]
}

export type BasicFacilityDetailDTORes = {
  facility: {
    id: string
    country: string
    postalCode: string
    type: string
    facilityName: string
    effectiveDate: string
    status: BASIC_FACILITY_DATA_STATUS
  }
  processingData: ProcessingData[]
}
