import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

// Category names for filtering categories
// [GET] /products/:productId/activities

export const PRODUCT_ACTIVITY_DETAIL_SUBPATH = ':productId/activities'
export const PRODUCT_ACTIVITY_DETAIL_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_ACTIVITY_DETAIL_SUBPATH}`

export class ActivityNamesDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string

  productId!: string
}

export type ActivityNamesDTORes = {
  activities: string[]
}
