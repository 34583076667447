export enum BASIC_CALCULATION_FORM_STATUSES {
  FILLED = 'FILLED',
  NOT_USED = 'NOT_USED',
  UNKNOWN = 'UNKNOWN',
}

export enum BASIC_CALCULATION_FORM_TYPES {
  FACILITY = 'FACILITY',
  TRANSPORTATION = 'TRANSPORTATION',
  PURCHASE = 'PURCHASE',
}

export enum BASIC_CALCULATION_STATUSES {
  IN_PROGRESS = 'in_progress',
  PUBLISHED = 'published',
  INGESTED = 'ingested',
  INGESTION_ERROR = 'ingestion_error',
  EXTERNAL_ERROR = 'external_error',
}
