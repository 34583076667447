import { IsNumber, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { BASIC_PCF_CALCULATIONS_CONTROLLER } from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

import type { TProductBasicCalculatorFacilities } from '../../../@types/basicCalculator/ProductBasicCalculator'

export const GET_BASIC_PCF_CACHE_SUBPATH = 'cache/:year'
export const GET_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${GET_BASIC_PCF_CACHE_SUBPATH}`

export class GetBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsNumber()
  year!: number
}

export type GetBasicPCFCacheRes = {
  facilitiesForms: TProductBasicCalculatorFacilities
  year: number
  cacheId: string
}
