<template>
  <component
    :is="element"
    :class="['heading', `heading--${computedSize}`, { 'heading--without-margin': withoutMargin }]"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  /**
   * Heading.vue
   *
   * This component handles or will handle all headings in the app.
   * The goal is to make it as dev friendly as possible.
   * The only required prop is the element we want to use e.g. h1.
   * The semantics and the styling can be decoupled using the size prop.
   * The default margin can be removed with the withoutMargin prop.
   */

  type ELEMENTS = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  type HEADING_SIZES = 'alpha' | 'beta' | 'gamma' | 'delta' | 'epsilon' | 'zeta'

  const HEADING_ELEMENT_TO_DEFAULT_SIZE_MAP: { [key in ELEMENTS]: HEADING_SIZES } = {
    h1: 'alpha',
    h2: 'beta',
    h3: 'gamma',
    h4: 'delta',
    h5: 'epsilon',
    h6: 'zeta',
  }

  type Props = {
    element: ELEMENTS
    size?: HEADING_SIZES
    withoutMargin?: boolean
  }

  const props = defineProps<Props>()

  const computedSize = computed<HEADING_SIZES>(() => {
    return props.size || HEADING_ELEMENT_TO_DEFAULT_SIZE_MAP[props.element]
  })
</script>

<style lang="scss" scoped>
  $module: 'heading';

  .#{$module} {
    font-family: $font-family-headings;
    font-weight: 700;

    &--alpha {
      font-size: $font-size-1;
      line-height: math.div(48, $font-size-1-unitless);
      margin-bottom: $grid-size-margin * 3;
    }

    &--beta {
      font-size: $font-size-2;
      line-height: math.div(40, $font-size-2-unitless);
      margin-bottom: $grid-size-margin * 3;
    }

    &--gamma {
      font-size: $font-size-3;
      line-height: math.div(32, $font-size-3-unitless);
      margin-bottom: $grid-size-margin;
    }

    &--delta {
      font-size: $font-size-4;
      line-height: math.div(24, $font-size-4-unitless);
      margin-bottom: $grid-size-margin;
    }

    &--epsilon {
      font-size: $font-size-5;
      line-height: math.div(24, $font-size-5-unitless);
      margin-bottom: $grid-size-margin;
    }

    &--zeta {
      font-size: $font-size-6;
      line-height: math.div(20, $font-size-6-unitless);
      margin-bottom: $grid-size-margin;
    }

    &--without-margin {
      margin-bottom: 0;
    }
  }
</style>
