import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'

// Bring this back when we use the API
// import { GET_TAG_OPTIONS_URI, type GetTagOptionsDTORes } from '@lib/DTO/config/get-tag-options.dto'

import { TYPE_OPTIONS } from '@lib/constants/basicCalculator/typeOptions'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { AsanaCompany } from '@/imports/@types/Asana'
import type { Country, State, REGION_CATEGORY } from '@/imports/@types/Country'
import type { Currency } from '@/imports/@types/Currency'
import type { Unit } from '@/imports/@types/Unit'
import type { GetSubActivityByIdRes } from '@/imports/@types/api/GetSubActivityById'
import type { ActivityDropdownData } from '@/imports/@types/activity/activityDropdownData'
import type { GetRegionsResponse } from '@/imports/@types/api/GetRegions'
import type { TEMPORARY_HARDCODED_TAG_TYPES } from '@/imports/@enums/tags.enums'

import { DIMENSIONALITY } from '@/imports/@enums/dimensionality.enums'

export const useConfigApi = (http: AxiosInstance) => ({
  getAsanaCompanyIds: useMemoize((orgId: string) => {
    return http.get<ApiResponseInner<AsanaCompany[]>>('/config/getasanacompanyids', { params: { orgId } })
  }),

  getCountries() {
    return http.get<ApiResponseInner<Country[]>>('/config/getcountries')
  },

  getStates(countryCode: string) {
    return http.get<{ states: State[] }>('/config/states', { params: { countryCode } })
  },

  getCurrencies() {
    return http.get<ApiResponseInner<Currency[]>>('/config/getcurrencies')
  },

  getUnits(dimensionalities?: DIMENSIONALITY[]) {
    const params = dimensionalities ? { includeDim: dimensionalities } : {}

    return http.get<ApiResponseInner<Unit[]>>('/config/getunits', { params })
  },

  getSubActivityById(subActivityId: string | number, orgId: string) {
    return http.get<{ subActivity: GetSubActivityByIdRes }>('/config/getsubactivitybyid', {
      params: {
        subActivityId,
        orgId,
      },
    })
  },

  getAllActivities() {
    return http.get<ApiResponseInner<ActivityDropdownData[]>>('/config/getallactivities')
  },

  getOnboardingActivities(orgId: string) {
    return http.get<ApiResponseInner<ActivityDropdownData[]>>('/config/getonboardingactivities', { params: { orgId } })
  },

  getRegions(category: REGION_CATEGORY) {
    return http.get<GetRegionsResponse>('/config/regions', { params: { category } })
  },

  getTagTypeOptions(types: TEMPORARY_HARDCODED_TAG_TYPES[]) {
    // TODO: remove this temporary hard coding which is needed due to v_tags limitations
    return {
      data: {
        result: types.reduce(
          (acc, curr) => {
            acc[curr] = TYPE_OPTIONS[curr]

            return acc
          },
          {} as { [key in TEMPORARY_HARDCODED_TAG_TYPES]: string[] },
        ),
      },
    }

    // return http.get<{ result: GetTagOptionsDTORes }>(GET_TAG_OPTIONS_URI, {
    //   params: {
    //     types,
    //   },
    // })
  },
})
