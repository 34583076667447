import type { AxiosInstance } from 'axios'
import { omit } from 'lodash-es'

import {
  ANALYTICS_INTERACTIVE_DASHBOARD_URI,
  AnalyticsInteractiveDashboardDTOReq,
  type AnalyticsInteractiveDashboardDTORes,
} from '@lib/DTO/analytics/get-interactive-dashboard-details.dto'

import {
  ANALYTICS_STATIC_DASHBOARD_URI,
  AnalyticsStaticDashboardDTOReq,
  type AnalyticsStaticDashboardDTORes,
} from '@lib/DTO/analytics/get-static-dashboard-details.dto'
import { replaceUriParams } from '@lib/DTO'
import {
  ANALYTICS_LOGIN_DASHBOARD_URI,
  AnalyticsLoginDTOReq,
  type AnalyticsLoginDTORes,
} from '@lib/DTO/analytics/analytics-sso-login.dto'
import {
  ANALYTICS_PERSONAL_COLLECTION_DASHBOARD_URI,
  AnalyticsPersonalCollectionDashboardDTOReq,
  type AnalyticsPersonalCollectionDashboardDTORes,
} from '@lib/DTO/analytics/get-personal-collection-details.dto'
import {
  ANALYTICS_SHARED_COLLECTION_DASHBOARD_URI,
  AnalyticsSharedCollectionDashboardDTOReq,
  type AnalyticsSharedCollectionDashboardDTORes,
} from '@lib/DTO/analytics/get-shared-collection-details.dto'

export const useCustomDashboardApi = (http: AxiosInstance) => ({
  getJWTSSOUrl(orgId: string, params: AnalyticsLoginDTOReq) {
    return http.get<AnalyticsLoginDTORes>(replaceUriParams(ANALYTICS_LOGIN_DASHBOARD_URI, params), {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getInteractiveDashboardUrl(orgId: string, params: AnalyticsInteractiveDashboardDTOReq) {
    return http.get<AnalyticsInteractiveDashboardDTORes>(
      replaceUriParams(ANALYTICS_INTERACTIVE_DASHBOARD_URI, params),
      {
        params,
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  getStaticDashboardUrl(orgId: string, params: AnalyticsStaticDashboardDTOReq) {
    return http.get<AnalyticsStaticDashboardDTORes>(replaceUriParams(ANALYTICS_STATIC_DASHBOARD_URI, params), {
      params: omit(params, 'embedId'),
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getSharedDashboardUrl(orgId: string, params: AnalyticsSharedCollectionDashboardDTOReq) {
    return http.get<AnalyticsSharedCollectionDashboardDTORes>(ANALYTICS_SHARED_COLLECTION_DASHBOARD_URI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getPersonalDashboardUrl(orgId: string, params: AnalyticsPersonalCollectionDashboardDTOReq) {
    return http.get<AnalyticsPersonalCollectionDashboardDTORes>(ANALYTICS_PERSONAL_COLLECTION_DASHBOARD_URI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
