import { IsUUID, IsOptional, IsString } from 'class-validator'

import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

// used in both "details" and "snapshot" pages
// Total product carbon impact
// &&
// Product carbon footprint (totalImpact/itemsProduced)

// [GET] /products/:productId/metric-carbon-impact?startMonth=01-2022&endMonth=12-2022&facilityId=xxx
export const PRODUCT_METRIC_CARBON_IMPACT_SUBPATH = ':productId/metric-carbon-impact'
export const PRODUCT_METRIC_CARBON_IMPACT_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_METRIC_CARBON_IMPACT_SUBPATH}`

export class ProductTotalImpactDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string

  @IsString()
  productId!: string

  @IsUUID()
  @IsOptional()
  facilityId?: string

  @IsUUID()
  @IsOptional()
  buId?: string
}

export type ProductTotalImpactDTORes = {
  totalImpact: number
  totalImpactUnit: string
  itemsProduced: number
  itemsProducedUnit: string
  totalProductWeight: number
  totalProductWeightUnit: string
}
