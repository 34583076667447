import type { AxiosInstance } from 'axios'

import { POST_DATA_IMPORTER_JWT_TOKEN_URI } from '@/imports/lib/DTO/one-schema-upload/auth/post-jwt-token.dto'

import { CALC_METHOD } from '@/imports/@enums/calcMethodCode.enum'

export const useDataImporterApi = (http: AxiosInstance) => ({
  getJwtToken(orgId: string, dataSourceId: string, calcMethodCode?: CALC_METHOD) {
    return http.post(
      POST_DATA_IMPORTER_JWT_TOKEN_URI,
      { dataSourceId, calcMethodCode },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },
})
