<template>
  <svg
    :width="width"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 5.375C5.75 6.53532 6.21094 7.64812 7.03141 8.46859C7.85188 9.28906 8.96468 9.75 10.125 9.75C11.2853 9.75 12.3981 9.28906 13.2186 8.46859C14.0391 7.64812 14.5 6.53532 14.5 5.375C14.5 4.21468 14.0391 3.10188 13.2186 2.28141C12.3981 1.46094 11.2853 1 10.125 1C8.96468 1 7.85188 1.46094 7.03141 2.28141C6.21094 3.10188 5.75 4.21468 5.75 5.375V5.375Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 19.75C2 17.5951 2.85602 15.5285 4.37976 14.0048C5.90349 12.481 7.97012 11.625 10.125 11.625C12.2799 11.625 14.3465 12.481 15.8702 14.0048C17.394 15.5285 18.25 17.5951 18.25 19.75"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.35498 3.15332C7.03233 3.85612 7.84446 4.4151 8.7428 4.79683C9.64114 5.17856 10.6072 5.3752 11.5833 5.37499C12.5719 5.37527 13.5502 5.17394 14.4583 4.78332"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;

    path {
      stroke: $grey-2;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
